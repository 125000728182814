import * as React from 'react';

import {
  Container,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';

import { AppButtons, ThemedWrapper } from '../../components/ui';
import { useVerifyUserMutation } from '../../apollo/generated';
import { parseQueryString } from '../../utils/helpers';
import '../../components/ui/sidebar/layout.css';

interface VerifyProps {
  userId: string
  verificationCode: string
}

const Verify = ({ userId, verificationCode }: VerifyProps) => {
  const [verifyUser, { data, loading, error }] = useVerifyUserMutation();

  const handleVerify = React.useCallback(async () => {
      return await verifyUser({
        variables: {
          userId,
          verificationCode,
        },
      });
  }, [verifyUser, userId, verificationCode]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      handleVerify();
    }
  }, [handleVerify]);

  type VerificationStatus = 'init' | 'invalid' | 'loading' | 'verified' | 'error';
  const getStatus = (): VerificationStatus => {
    if (error?.message) {
      return 'error';
    } else if (loading) {
      return 'loading';
    } else if (!error && !!data?.verifyUser?.verified) {
      return 'verified';
    } else if (!userId || !verificationCode || data?.verifyUser?.verified === false) {
      return 'invalid';
    }

    return 'init';
  };

  const status = getStatus();

  const getHeader = () => {
    switch (status) {
      case 'init': return 'Loading, please wait...';
      case 'error': return 'Failed to verify';
      case 'loading': return 'Verification in progress, please wait...';
      case 'verified': return 'Verification successful.';
      default: return 'Invalid verification link.';
    }
  };

  const getBodyText = () => {
    switch (status) {
      case 'error': return error?.message;
      case 'invalid': return 'Please contact support.';
      default: return '';
    }
  }

  const bodyText = getBodyText();

  return (
    <ThemedWrapper>
      <Container>
        <Grid container alignItems="center" direction="column" spacing={1}>
          {(status === 'error' || status === 'invalid') && (
            <Grid item>
              <Error fontSize="large" />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h6">{getHeader()}</Typography>
          </Grid>
          {status === 'verified' && (
            <>
              <Grid item>
                <Typography variant="body1">Get the mobile app now!</Typography>
              </Grid>
              <Grid item>
                <AppButtons/>
              </Grid>
            </>
          )}
          {(status === 'init' || status === 'loading') && (
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
          )}
          {bodyText && (
            <Grid item>
              <Typography variant="body1">{bodyText}</Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </ThemedWrapper>
  );
};

const VerifyWrapper = () => {
  const { user_id, verification_code } = parseQueryString();

  const firstElement = (val: string | string[]) => Array.isArray(val) ? val[0] : val;
  const userId = firstElement(user_id),
    verificationCode = firstElement(verification_code);

  return <Verify userId={userId} verificationCode={verificationCode} />
};

export default VerifyWrapper;
