import queryString from "query-string"
import Swal, { SweetAlertType } from 'sweetalert2'

export const formatTradeGroup = (tradeGroup: string) => {
  return tradeGroup
    .charAt(0) + tradeGroup.slice(1).toLowerCase()
      .replace(/_/g, ' ');
}

export const nameAbbr = (name?: string) => (name || '').split(' ').map(n => n[0]).join('').substring(0, 3).toUpperCase()

export const confirmationDialog = (title: string, buttonText?: string, body?: string, modalType?: SweetAlertType): Promise<boolean> => (
  new Promise(r => {
    Swal.fire({
      title,
      text: body || 'Are you sure you want to do this?',
      type: modalType || 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: buttonText || 'Yes',
      reverseButtons: true
    }).then((result) => {
      r(!!result.value)
    })
  })
)

export const errorAlert = (e: any) => {
  Swal.fire('An Error Occurred', (e.msg || e.message || '').replace(/graphql error: /i, ''), 'error')
}

export const getHashValueAndRemove = (ready: boolean, id: string): string | string[] => {
  if (typeof window !== 'undefined') {
    const queryStrings = queryString.parse(location.hash);
    const value = queryStrings[id];
    if (ready) {
      location.hash = queryString.stringify({
        [id]: undefined
      })
    }
    return value;
  }
}

export const parseQueryString = (): queryString.ParsedQuery => {
  if (typeof window === 'undefined') {
    return {};
  }
  return queryString.parse(window?.location?.search);
}
